import Swal from 'sweetalert2'

export default {
  methods: {
    // Alerts with messages
    async savedMessage(errDesc, title, name_ru, name_en = null) {
      const alert = {
        icon: errDesc ? 'error' : 'success',
        title,
        text: this.$t(`messages.was_${errDesc ? 'not_' : ''}saved`, { name_ru, name_en: name_en || name_ru }),
        footer: errDesc
      };

      await Swal.fire(alert);
    },

    successMessage(title, details) {
      // Show success message
      Swal.fire(title, details, 'success')
    },

    deletedMessage(details) {
      // Show deleted message
      Swal.fire(this.$t('messages.deleted'), this.$t('messages.you_deleted', { name: details }), 'success')
    },

    errorMessage(err) {
      // Show error message
      Swal.fire(this.$t('messages.error'), err, 'error')
    },

    approvedMessage(status, name) {
      // Show status (approved/revoked) message
      Swal.fire(status ? this.$t('label.approved') : this.$t('messages.revoked'), name, 'success')
    },

    assignedMessage(status, count) {
      // Show message with count of assigned/unassigned works
      const key = `${status < 0 ? 'un' : ''}assigned`
      Swal.fire(this.$t(`messages.${key}`), this.$t(`messages.works_${key}`, { count: count }), 'success')
    },

    listExistsMessage() {
      Swal.fire(this.$t('messages.approved_hs_list_exists_title'), this.$t('messages.approved_hs_list_exists'), 'warning')
    },

    // Messages with confirmations
    async confirmation(title, details) {
      const alert = {
        icon: 'warning',
        title,
        html: details,
        showCancelButton: true,
        confirmButtonText: this.$t('upload.yes'),
        cancelButtonText: this.$t('buttons.cancel'),
        allowEscapeKey: false
      }
      return await Swal.fire(alert)
    },

    async deleteConfirmation(details) {
      const alert = {
        icon: 'warning',
        title: this.$t('messages.are_you_sure'),
        html: `${this.$t('messages.you_want_delete', { name: details })}<br><br>
        ${this.$t('messages.the_operation_cannot_be_canceled')}`,
        showCancelButton: true,
        confirmButtonText: this.$t('messages.yes_delete'),
        cancelButtonText: this.$t('buttons.cancel'),
      }
      return await Swal.fire(alert)
    },

    // Messages with user input
    async textInput(title, label, details = '') {
      const alert = {
        icon: !details ? null : 'warning',
        title,
        input: "text",
        html: details,
        inputPlaceholder: label,
        showCancelButton: true,
        cancelButtonText: this.$t('buttons.cancel'),
        allowOutsideClick: false,
        //inputValidator: () => { return !show ? false : show }      
      }
      return await Swal.fire(alert)
    },

    async dropdownInput(title, inputOptions, label) {
      const alert = {
        title,
        input: "select",
        inputPlaceholder: label,
        inputOptions: inputOptions,
        showCancelButton: true,
        cancelButtonText: this.$t('buttons.cancel'),
        allowOutsideClick: false,
      }
      return await Swal.fire(alert)
    },
  }
}
